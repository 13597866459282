import { createWebHistory, createRouter } from "vue-router";
import Home from "./components/Home.vue";
import Login from "./components/Login.vue";
import Register from "./components/Register.vue";

// lazy-loaded
const Profile = () => import("./components/Profile.vue")
const Admin = () => import("./components/Admin.vue")
const League = () => import("./components/League.vue")
const MyGames = () => import("./components/MyGames.vue")
const NewGame = () => import("./components/New.vue")
const GameLoader = () => import("./components/GameLoader.vue")
const Stats = () => import("./components/Stats.vue")
const OneVOne = () => import("./components/OneVOne.vue")

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/stats",
    component: Stats,
  },
  {
    path: "/register",
    component: Register,
  },
  {
    path: "/profile",
    name: "profile",
    // lazy-loaded
    component: Profile,
  },
  {
    path: "/admin",
    name: "admin",
    // lazy-loaded
    component: Admin,
  },
  {
    path: "/mygames",
    name: "mygames",
    // lazy-loaded
    component: MyGames,
  },
  {
    path: "/league",
    name: "league",
    // lazy-loaded
    component: League,
  },
  {
    path: "/onevone",
    name: "onevone",
    // lazy-loaded
    component: OneVOne,
  },
  {
    path: "/new",
    name: "new",
    // lazy-loaded
    component: NewGame,
  },
  {
    path: "/gameLoader:gameId?",
    name: "gameLoader",
    // lazy-loaded
    component: GameLoader,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
    const publicPages = ['/login', '/register', '/home', '/', '/league'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');
  
    // trying to access a restricted page + not logged in
    // redirect to login page
    if (authRequired && !loggedIn) {
      next('/login');
    } else {
      next();
    }
  });

export default router;