import axios from 'axios';
import authHeader from './auth-header';

const API_URL = '/api/tm/';

class TMService {
  getSettings(){
      return axios.get(API_URL + 'settings', { headers: authHeader() });
  }

  saveSettings(settings){
    return axios.post(API_URL + 'settings', settings, { headers: authHeader()});
  }

  getOpenGames(){
    return axios.get(API_URL + 'openGames');
  }

  createGame(game_id, options, players){
    let body = {
      "game_id": game_id, 
      "options": options,
      "players": players
    };
    return axios.post(API_URL + 'modifyGame', body, { headers: authHeader()});
  }

  leaveGame(game_id, user_id){
    let body = {
      "game_id": game_id, 
      "user_id": user_id
    };
    return axios.post(API_URL + 'leaveGame', body, { headers: authHeader()});
  }

  joinGame(game_id, user_id, colour){
    let body = {
      "game_id": game_id, 
      "user_id": user_id,
      "colour": colour
    };
    return axios.post(API_URL + 'joinGame', body, { headers: authHeader()});
  }

  addNotes(game_id, user_id, notes){
    let body = {
      "game_id": game_id, 
      "user_id": user_id,
      "notes": notes
    };
    return axios.post(API_URL + 'setNotes', body, { headers: authHeader()});
  }

  getMyGames(user_id){
    return axios.post(API_URL + 'myGames', {"user_id": user_id}, {headers: authHeader()})
  }

  getLastFinishedGames(user_id, pageP, sizeP){
    let user = user_id ? `&user_id=${user_id}` : '';
    let page = pageP ? `&page=${pageP}` : '';
    let size = sizeP ? `&size=${sizeP}` : '';
    return axios.get(API_URL + `finishedGames?d=0${user}${page}${size}`, {headers: authHeader()})
  }

  getSeriesGames(series){
    return axios.get(API_URL + `seriesGames?series=${series}`, {headers: authHeader()});
  }

  getSeries(){
    return axios.get(API_URL + `series`, {headers: authHeader()});
  }

  readChat(game_id)
  {
    return axios.post(API_URL + 'readChat', {"game_id": game_id}, {headers: authHeader()})
  }

  sendMessage(game_id, message)
  {
    return axios.post(API_URL + 'sendMessage', {"game_id": game_id, "message": message}, {headers: authHeader()})
  }

  readMessages(game_id)
  {
    return axios.get(API_URL + `getMessages?game_id=${game_id}`, {headers: authHeader()});
  }

  resign(game_id, user_id){
    let body = {
      "game_id": game_id, 
      "user_id": user_id
    };
    return axios.post(API_URL + 'resign', body, { headers: authHeader()});
  }

  findGame(game_idtext){
    return axios.get(API_URL + `findGame?game=${game_idtext}`, {headers: authHeader()});
  }

  setStatus(game_id, status)
  {
    let body = {
      "game_id": game_id, 
      "status": status
    };
    return axios.post(API_URL + 'setStatus', body, { headers: authHeader()});
  }

  startGame(game_id)
  {
    let body = {
      "game_id": game_id
    };
    return axios.post(API_URL + 'startGame', body, { headers: authHeader()});
  }

  updateGame(game_id, options)
  {
    let body = {
      "game_id": game_id,
      "options": options,
    };
    return axios.post(API_URL + 'updateGame', body, { headers: authHeader()});
  }

}

export default new TMService();