<template>
    <ul>
        <li v-if="options.numPlayers">{{options.numPlayers + ' jogadores'}}</li>
        <li v-if="options.anon == true">Anonimo</li>
        <li v-if="options.prelude">Prelude</li>
        <li v-if="options.venus">Venus</li>
        <li v-if="options.colonies">Colonias</li>
        <li v-if="options.turmoil">Turmoil
            <span v-if="options.turmoilA">
            {{ options.turmoilAV == "Chairman" ? ' (Chairman escolhe)' : ' (Random Agendas)'}}
            </span>
        </li>
        <li v-if="options.trajectory">Trajectory</li>
        <li v-if="options.hasOwnProperty('ptvis') && !options.ptvis">Pontos Escondidos</li>
        <li v-if="options.moon" class="red">Moon</li>
        <li v-if="options.ares" class="red">Ares</li>
        <li v-if="options.fanMade" class="red">Fan Made corps</li>
    </ul>
</template>

<script>
export default {
  name: "GameOptions",
  props: ['options']
};
</script>

<style scoped>
    ul { 
        padding-left: 0;
    }

    .red {
        color: red;
    }
</style>