<template>
    <div v-bind:class="{'chat-window': position != 'inline', 'chat': true}" v-if="openChat" v-on:keyup.space.prevent>
        <div class="chat-message">
            <!-- Reversed to use flex trick to scroll to bottom -->
            <div v-if="messages.length > 0 && newMessages.length == 0" class="no-new-message-warning shade">You have no new messages</div>
            <Message v-for="(m, index) in newMessages" :key="index" :message="m"></Message>
            <div v-if="newMessages.length > 0" class="new-message-warning">New messages</div>
            <Message v-for="(m, index) in oldMessages" :key="index" :message="m"></Message>
            <div v-if="messages.length == 0" class="shade">You have no messages!</div>
        </div>
        <div>
            <input type="text" class="form-control chat-message-input" id="name" v-on:keyup.enter="enterMsg()" v-model="message" autocomplete="off">
            <button type="button" class="btn btn-primary btn-lg send" @click="enterMsg">
                <font-awesome-icon class="send-btn" icon="share" />
            </button>
        </div>
    </div>
</template>

<script>
import TMService from "../../services/tm.service";
import Message from "./Message";

export default {
  name: "Chat",
  components: { Message },
  props: ['game','openChat','position'],
  data() {
    return {
      message: "",
      errorMessage: "",
      messages: [],
      lastread: "",
      loading: true
    }
  },
  async mounted(){
    try
    {
        await this.getMessages();
    }
    catch(err)
    {
        console.log(err);
    }
  },
  computed:{
    oldMessages() {
        return this.messages.filter(m => this.lastread != "" && m.timestamp <= this.lastread);
    },
    newMessages() {
        return this.messages.filter(m => this.lastread == "" || m.timestamp >= this.lastread);
    }
  },
  watch:{
    async openChat(){
        try
        {
            await this.getMessages();
        }
        catch(err)
        {
            console.log(err);
        }
    }
  },
  methods: {
      async enterMsg(){
        console.log(this.message);
        try {
           if(this.message)
           {
            await TMService.sendMessage(this.game, this.message);
            let resp = await TMService.readMessages(this.game)
            this.messages = resp.data.messages;
            this.message = "";
           }
        } catch (error) {
            this.loading = false;
            this.errorMessage =
            (error.response &&
                error.response.data &&
                error.response.data.message) ||
            error.message ||
            error.toString();
        }
      },
      async getMessages()
      {
        if(this.openChat)
        {
            let resp = await TMService.readMessages(this.game);
            this.messages = resp.data.messages;
            this.lastread = resp.data.lastRead;
            
            await TMService.readChat(this.game);
        }   
      }
  }
}
</script>

<style scoped>

    .chat {
        position: relative;
        background-color: white;
        border: 1px solid black;
        cursor: auto;
        color: black;
        height: 300px;
        width: 100%;
    }

    .chat-window {
        position: relative;
        left: -460px;
        background-color: white;
        top: 10px;
        width: 500px;
    }

    .chat-message {
        display: flex;
        flex-direction: column-reverse;
        overflow-y: scroll;
        height: calc(100% - 38px);
    }
    
    .chat-message-input {
        width: calc(100% - 38px);
        display: inline;
    }

    .btn.send {
        width:38px;
        height: 38px;
        display: inline-block;
    }

    .send-btn {
        vertical-align: top;
        margin-left: -5px;
    }

    .new-message-warning {
        color: red;
        font-size: 0.8em;
    }
    .shade {
        color: '#A2A2A2';
        font-size: 0.6em;
    }
</style>