import axios from 'axios';
import authHeader from './auth-header';

const API_URL = '/api/auth/';

class UserService {
  getUsers() {
    return axios.get(API_URL + 'users', { headers: authHeader()});
  }

  getUser(user_id) {
    return axios.get(API_URL + `user?id=${user_id}`, { headers: authHeader()});
  }
}

export default new UserService();