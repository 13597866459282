import { createApp } from 'vue'
import App from './App.vue'
import router from "./router";
import store from "./store";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from './plugins/font-awesome'
import iframeResize from 'iframe-resizer/js/iframeResizer';

 

createApp(App)
  .use(router)
  .use(store)
  .component("font-awesome-icon", FontAwesomeIcon)
  .directive('resize', {
    bind: function(el, { value = {} }) {
      el.addEventListener('load', () => iframeResize(value, el))
    },
    unbind: function (el) {
      el.iFrameResizer.removeListeners();
    }
  })
  .mount("#app");
