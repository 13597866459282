<template>
    <div class="message">
        <span><strong>{{message.colour}}> </strong>{{message.message}}</span>
    </div>
</template>

<script>

export default {
  name: "Message",
  props: ['message']
}
</script>

<style scoped>
    .message {
        font-size: 0.7em;
        text-align: left;
        padding-left: 2px;
    }
</style>