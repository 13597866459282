<template>
  <div id="app">
    <nav class="navbar navbar-expand navbar-dark bg-dark">
      <div class="container-fluid">
        <div class="navbar-nav mr-auto">
          <li class="nav-item">
            <router-link to="/home" class="nav-link">
              <font-awesome-icon icon="home" />
            </router-link>
          </li>
          <li v-if="currentUser" class="nav-item">
            <router-link to="/mygames" class="nav-link">
              <font-awesome-icon icon="th-list" />
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/league" class="nav-link position-relative">
              <font-awesome-icon icon="trophy" />
              <span class="position-absolute translate-middle p-1 bg-danger rounded-circle">
                <span class="visually-hidden">New league</span>
              </span>
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/onevone" class="nav-link position-relative">
              <font-awesome-icon icon="people-arrows" />
            </router-link>
          </li>
           <li v-if="currentUser" class="nav-item">
            <router-link to="/stats" class="nav-link">
              <font-awesome-icon icon="chart-bar" />
            </router-link>
          </li>
          <li v-if="currentUser" class="nav-item">
            <router-link to="/new" class="nav-link">
              <font-awesome-icon icon="plus-circle" />
            </router-link>
          </li>
        </div>

        <div v-if="!currentUser" class="navbar-nav ms-auto">
          <li class="nav-item">
            <router-link to="/register" class="nav-link">
              <font-awesome-icon icon="user-plus" /> Sign Up
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/login" class="nav-link">
              <font-awesome-icon icon="sign-in-alt" /> Login
            </router-link>
          </li>
        </div>

        <div v-if="currentUser" class="navbar-nav ms-auto">
          <span class="navbar-text">
            {{`${currentUser.username} (${currentUser.openskill})`}}          
          </span>
          <li v-if="showAdminBoard" class="nav-item">
            <router-link to="/admin" class="nav-link">
              <font-awesome-icon icon="user-cog" />
            </router-link>
          </li>
          <li class="nav-item">
            <router-link to="/profile" class="nav-link">
              <font-awesome-icon icon="user" />
            </router-link>
          </li>
        </div>
      </div>
    </nav>

    
    <router-view />
    
  </div>
</template>

<script>
import UserService from "./services/user.service";
export default {
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    showAdminBoard() {
      if (this.currentUser && this.currentUser['roles']) {
        return this.currentUser['roles'].includes('ADMIN');
      }
      return false;
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    }
  },
  mounted(){
    if(this.currentUser){
      UserService.getUser(this.currentUser.user_id).then(
        (response) => {
          this.currentUser.openskill = ((response.data.openskill || 0) + 50).toFixed(2);
        });
    }
  }
};
</script>

<style scoped>
  
</style>